<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="BILL_SETTS"
      tooltip-title="BILL_SETTS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
    <div
      class="min-h-screen settings-bar pt-7 mt-3 px-3 md:px-7 border rounded-t-lg border-border-color-lighter"
    >
      <div v-if="!isLoading">
        <!-- V-FOR START -->
        <div
          class="flex w-full flex-row flex-wrap gap-y-1 md:flex-row text-sm text-text-color font-roboto font-normal"
        >
          <!-- Name -->
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0 relative">
            <InputBox
              v-model="setting.title"
              type="text"
              :rules="`required`"
              name="name"
              title="Name"
              label="Name"
              placeholder="name"
              class="flex-1"
              is-requried="true"
            />
          </div>
          <!-- Institute -->
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0 relative">
            <InputBox
              v-model="setting.institute_name"
              type="text"
              :rules="`required`"
              name="institute"
              title="INSTITUTE"
              label="Institute"
              placeholder="INSTITUTE"
              class="flex-1"
              is-requried="true"
            />
          </div>
          <!-- PHONE NUMBER -->
          <div class="sm:pr-6 pr-0 sm:w-1/2 w-full sm:pt-0 pt-0 relative">
            <PhoneNumber
              v-model="setting.phone_field"
              title="Phone Number"
              :rules="`required`"
              :is-verified="setting.is_phone_approved"
              @update="updatePhoneNumber"
            />
          </div>
          <!-- EMAIL -->
          <div class="sm:w-1/2 w-full sm:pt-0 pt-4 sm:pr-6 pr-0 relative">
            <InputBox
              v-model="setting.email"
              type="email"
              :rules="`required`"
              name="Email"
              title="Email"
              label="Email"
              placeholder="email"
              :is-verified="setting.is_email_approved"
              class="flex-1"
              is-requried="true"
            />
          </div>
          <!-- ADDRESS -->
          <div class="w-full sm:pt-0 pt-4 sm:pr-6 pr-0">
            <InputBox
              v-model="setting.address"
              type="text"
              :rules="`required`"
              name="BILLING_ADDRESS"
              title="BILLING_ADDRESS"
              label="Billing Address"
              placeholder="BILLING_ADDRESS"
              class="flex-1"
            />
          </div>
        </div>
        <div class="pb-5 md:pb-0">
          <button
            class="mt-5 mb-5 focus:outline-none focus:shadow-md ltr:text-base rtl:text-sm text-white font-rubik flex w-full md:w-40 py-2 justify-center primary-button"
            @click.prevent="handleSubmit(apply(invalid))"
          >
            {{ $t('dashboard.Save') }}
          </button>
        </div>
        <!-- DIVIDER -->
        <div class="divide mt-4 mb-8"></div>
      </div>
      <!-- LOADING -->
      <div v-else class="h-44 pb-28">
        <Loader class="" :content="true" />
      </div>
    </div>
    <!-- </ValidationObserver> -->
  </div>
</template>

<script>
/* COMPONENTS */
import TitleWrapper from '@components/TitleWrapper.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { mapActions, mapState } from 'vuex'

/* STORE */

export default {
  name: 'BillingSettings',
  components: {
    TitleWrapper,
    InputBox,
    // ValidationObserver,
    Loader,
    PhoneNumber,
  },
  data() {
    return {
      setting: {},
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  async mounted() {
    await this.getBillingSetting()
    this.loadStateSettings()
  },
  methods: {
    /**
     * SAVING SETTINGS
     *  */
    async apply(invalid) {
      if (!invalid) {
        await this.setOrUpdateBillingSetting(this.setting)
        this.loadStateSettings()
      }
    },
    /**
     * UPDATING NUMBER
     * */
    updatePhoneNumber(payload) {
      this.setting.phone = payload.formattedNumber
      this.setting.phone_field = payload.formattedNumber
    },
    /**
     * Loading State Settings
     */
    loadStateSettings() {
      this.setting = JSON.parse(JSON.stringify(this.$store.state.settings.billingSetting))
      this.setting.phone_field = this.setting.phone
    },
    ...mapActions('settings', ['getBillingSetting', 'setOrUpdateBillingSetting']),
  },
}
</script>

<style lang="scss" scoped>
.settings-bar {
  background-color: var(--bg-color-white);
}
.divide {
  border-top: 2px solid #ecedef;
}
</style>
